import {
  getEligibilityAsync,
  injectChatAssets,
  getChatDomain,
  addContainer,
  getCookie,
  checkScreenEligible
} from "./app";

import { tfProdRegions } from "./constants";

let tfProd = false;
let fscaProd = false;

const run = () => {
  const chatDomain = getChatDomain();
  let chatDomainResources = chatDomain;
  // For the time being we are using root domain for thermofisher only, we will remove this later
  if (chatDomain === "https://chat-api.thermofisher.com") {
    chatDomainResources = "https://www.thermofisher.com";
    tfProd = true;
  }
  // for fishersci.ca region
  if (chatDomain === 'https://chat-api.fishersci.com') {
    fscaProd = true;
  }

  const isActiveChat = localStorage.getItem("chatSessionId") ? true : false;

  if (chatDomain === null) {
    console.error("Chat domain not found");
    return;
  }

  // Setting default values when no country n language cookie found
  const params = {
    countryCode: getCookie?.("CK_ISO_CODE") || getCookie?.('locale')?.split('_')[1].toLowerCase() || localStorage.getItem?.("chatCountryCode") || 'us',
    languageCode: getCookie?.("CK_LANG_CODE") || getCookie?.('locale')?.split('_')[0] || localStorage.getItem?.("chatLanguageCode") || 'en'
  };
  
  if(params.countryCode === undefined || params.countryCode === null || params.countryCode === '' || params.languageCode === undefined || params.languageCode === null || params.languageCode === '') {  
    console.error("Country code or language code not found");
    return;
  }
  
  const requestParam =
    encodeURIComponent(window.location.pathname + window.location.search + window.location.hash) +
    "&" +
    new URLSearchParams(params).toString();

  getEligibilityAsync(chatDomain, window.location.href, requestParam, chatDomainResources).then(
    function (response) {
      // check active chat if session localstorage is available
      console.warn('screenEligibility', response)
      if ((response.eligible === true || isActiveChat === true) && (response.screenEligibility ? checkScreenEligible(response.screenEligibility) : true)) {
        addContainer();
        injectChatAssets(chatDomainResources);
      }
    },
    function (err) {
      console.error(err);
    }
  );
};

run();
