import { domains, envTypes, CHAT_CONTAINER_ID, scriptFiles, styleFiles, tfRootDomain, getUserDetails,screen_configuration } from "./constants";

export const getChatDomain = function () {
  const hostname = window.location.hostname.toLocaleLowerCase();
  const availableHostnames = Object.keys(domains).join("|");

  const pattern = new RegExp(
    `(www)?((\\w*).)?(${availableHostnames}).((\\w{2}.\\w{2})|(\\w{2,3}))`,
    "gm"
  );
  const match = pattern.exec(hostname);

  if (match && match.length === 8) {
    const envType =
      !!match[3] && match[3].startsWith(envTypes.qa4)
        ? envTypes.qa4
        : match[3].startsWith(envTypes.dev)
          ? envTypes.dev
          : match[3].startsWith(envTypes.qa1)
            ? envTypes.qa1
            : match[3].startsWith(envTypes.qa) || match[3].startsWith(envTypes.test)
              ? envTypes.qa
              : match[3].startsWith(envTypes.chemicals)
                ? envTypes.chemicals
                : envTypes.prod;
    const domain = match[4];
    // Exception for fishersci qa - ie and uk region
    if (domain === "fishersci" && envType === envTypes.qa1) {
      const region = match[5];
      if (region.endsWith("ie") || region.endsWith("uk")) {
        return domains[domain]?.[envTypes.qa] ?? null;
      }
    }

    // To fetch country and language code for ampliseq domain
    if (domain === "ampliseq") {
      const getUserDetailsEndpoint = 
        match[3].startsWith(envTypes.prod) 
        ? tfRootDomain?.[envTypes.prod] + getUserDetails 
        : match[3].startsWith(envTypes.dev) 
          ? tfRootDomain?.[envTypes.dev] + getUserDetails
          : tfRootDomain?.[envTypes.qa] + getUserDetails;
      // Set country and language code in local storage for ampliseq
      setCountryNLang(getUserDetailsEndpoint);
    }

    // Return chat domain for koreaChemicals
    if (envType === envTypes.chemicals) {
      if(hostname.includes("qa1-chemicals")) {
        return domains["koreaChemicals"]?.[envTypes.qa] ?? domains["koreaChemicals"]?.[envTypes.prod];
      }
    }

    return domains[domain]?.[envType] ?? null;
  }

  return null;
};

export const getEligibilityAsync = function (chatDomain, hostname, requestParam) {
  let eligibilityEndpoint = `/api/store/chat/fs/api/v1/session/eligibility`;

  if (chatDomain.includes("thermofisher")) {
    // update eligibility endpoint for thermofisher
    eligibilityEndpoint = `/api/store/chat/api/v1/session/eligibility`;
  }

  const url = `${chatDomain}${eligibilityEndpoint}?url=${requestParam}`;

  return fetch(url, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
    credentials: "include",
  }).then(
    function (response) {
      return response.json().then(
        function (data) {
          const eligibilityInfo = {
            eligible: data?.eligible,
            screenEligibility: data?.chatFeatures?.checkScreenEligibility
          };
          console.warn("Screen eligibility", eligibilityInfo);
          return eligibilityInfo;
        },
        function (err) {
          console.error(err);
          return false;
        }
      );
    },
    function (err) {
      console.error(err);
      return true;
    }
  );
};

async function setCountryNLang (url) {
  try {
    const response = await fetch(url);
    const res = await response.json();
    if(res?.data?.countryCode && res?.data?.lang) {
      localStorage.setItem("chatCountryCode", res.data.countryCode);
      localStorage.setItem("chatLanguageCode", res.data.lang);
    }
  } 
  catch (error) {
    console.error(error);
  }
}


export const checkScreenEligible = function(isScreenEligible) {
  return isScreenEligible === true && window.innerWidth >= screen_configuration;
}
export const injectScripts = function (chatDomain, files) {
  files.forEach((script) => _addScriptTag(chatDomain, script));
};

export const injectStyles = function (chatDomain, files) {
  files.forEach((script) => _addLinkStyleTag(chatDomain, script));
};

export const injectChatAssets = function (chatDomain) {
  const chatScriptFiles = [... scriptFiles];
  const chatStyleFiles = [... styleFiles];

  fetchChatAssets(chatDomain, chatScriptFiles, chatStyleFiles)
};

const fetchChatAssets = async function (chatDomain, chatScriptFiles, chatStyleFiles) {
  const response = await fetch(`${chatDomain}/search/chat/handler/readChatAssets`);
  if (response) {
    const data = await response.json();
    if (data?.entrypoints?.app?.assets?.js) {
      chatScriptFiles = [];
      data.entrypoints.app.assets.js.forEach((script) =>
        chatScriptFiles.push("/search/chat/" + script)
      );
      console.log("Chat style files", chatScriptFiles);
    }
    if (data?.entrypoints?.app?.assets?.css) {
      chatStyleFiles = [];
      data.entrypoints.app.assets.css.forEach((style) =>
        chatStyleFiles.push("/search/chat/" + style)
      );
      console.log("Chat style files", chatStyleFiles);
    }
  }
    
  console.log("Injecting chat assets");
  injectScripts(chatDomain, chatScriptFiles);
  injectStyles(chatDomain, chatStyleFiles);
};

export const addContainer = function () {
  let container = document.getElementById(CHAT_CONTAINER_ID);
  if (!container) {
    container = document.createElement("div");
    container.id = CHAT_CONTAINER_ID;
    document.body.appendChild(container);
  }
};

const _addScriptTag = function (chatDomain, script) {
  const scriptElement = document.createElement("script");
  scriptElement.src = `${chatDomain}${script}`;
  scriptElement.defer = true;

  document.body.appendChild(scriptElement);
};

const _addLinkStyleTag = function (chatDomain, style) {
  const styleElement = document.createElement("link");
  if (style.includes("fonts.googleapis.com")) {
    styleElement.href = `${style}`;
  }
  else {
    styleElement.href = `${chatDomain}${style}`;
  }
  styleElement.rel = "stylesheet";

  document.head.appendChild(styleElement);
};

export const checkCookieTrueValue = function (name) {
  var match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"));
  return match ? (match[2] === "true" ? true : false) : false;
};

export const getCookie = function (name) {
  const match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"));
  if (match) return match[2];
};

// Dummy commit 4
