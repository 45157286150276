export const envTypes = {
  qa4: "qa4",
  qa1: "qa1",
  qa: "qa",
  prod: "prod",
  dev: "dev",
  test: "test",
  chemicals: "chemicals"
};

export const domains = {
  thermofisher: {
    [envTypes.qa4]: "https://qa4-chat-api.thermofisher.com",
    [envTypes.qa1]: "https://qa1-chat-api.thermofisher.com",
    [envTypes.qa]: "https://qa1-chat-api.thermofisher.com",
    [envTypes.prod]: "https://chat-api.thermofisher.com"
  },
  fishersci: {
    [envTypes.qa4]: "https://qa4-chat-api.fishersci.com",
    [envTypes.qa1]: "https://qa4-chat-api.fishersci.com",
    [envTypes.qa]: "https://qa1-chat-api.fishersci.com",
    [envTypes.prod]: "https://chat-api.fishersci.com"
  },
  punchout: {
    [envTypes.qa]: "https://qa1-chat-api.fishersci.com",
    [envTypes.prod]: "https://chat-api.fishersci.com"
  },
  identity:{
    [envTypes.qa]:  "https://qa1-chat-api.thermofisher.com",
    [envTypes.prod]: "https://chat-api.thermofisher.com",
    [envTypes.dev]: "https://qa4-chat-api.thermofisher.com"
  },
  ampliseq: {
    [envTypes.qa]: "https://qa1-chat-api.thermofisher.com",
    [envTypes.prod]: "https://chat-api.thermofisher.com",
    [envTypes.dev]: "https://qa4-chat-api.thermofisher.com"
  },
  koreaChemicals: {
    [envTypes.qa]: "https://qa1-chat-api.thermofisher.com",
    [envTypes.prod]: "https://chat-api.thermofisher.com"
  }
};

export const tfRootDomain = {
  [envTypes.prod]: "https://www.thermofisher.com",
  [envTypes.qa]: "https://www.qa.thermofisher.com",
  [envTypes.dev]: "https://www.qa4.thermofisher.com"
}

export const tfProdRegions = ["us", "ca", "at", "be", "dk", "fi", "fr", "de", "ie", "it", "nl", "no", "pl", "pt", "es", "se", "ch", "uk", "lu"];
export const tfQaRegions = ["us", "uk", "de", "fr"];

export const scriptFiles = [`/search/chat/js/chunk-vendors.js`, `/search/chat/js/app.js`];

export const styleFiles = [
  `/search/chat/css/app.css`,
  `https://fonts.googleapis.com/icon?family=Material+Icons`
];

export const CHAT_CONTAINER_ID = "chatAppContainer";

export const getUserDetails = "/api/store/user-type";

export const screen_configuration = 600;

// dummy commit 2

